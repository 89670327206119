<template>
	<!-- 仓库资料 -->
	<div class="warehouseData">
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus" @click.stop="addDialog">新增</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="仓库名称" prop="name" align="center"></el-table-column>
				<el-table-column label="类型" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">大仓（有库存、可提货）</span>
						<span v-if="scope.row.type == 2">提货仓（无库存、仅提货）</span>
					</template>
				</el-table-column>
				<el-table-column label="联系人" prop="contacts" align="center"></el-table-column>
				<el-table-column label="联系方式" prop="phone" align="center"></el-table-column>
				<el-table-column label="省市区" align="center">
					<template slot-scope="scope">
						<span>{{
                            scope.row.province_code.pro_name }}{{ scope.row.city_code.city_name }}{{
        scope.row.area_code.area_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="详细地址" prop="address" align="center"></el-table-column>
				<el-table-column label="配送线路" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.delivery_line">{{ scope.row.delivery_line.title }}</span>
						<span v-else> 不在配送范围内 </span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="warning" size="mini"
							@click.stop="dialogVisible = true, dialogType = 2, openDialog(scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click.stop="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="60%">
			<el-form ref="form" label-width="80px">
				<el-form-item label="仓库名称" required>
					<el-input v-model="form.name" placeholder="请输入仓库名称"></el-input>
				</el-form-item>
				<el-form-item label="类型" required>
					<el-radio-group v-model="form.type">
						<el-radio :label="1">大仓（有库存、可提货）</el-radio>
						<el-radio :label="2">提货仓（无库存、仅提货）</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="所属路线">
					<el-select v-model="form.delivery_line_name" placeholder="请选择配送路线,如不选择将根据位置自动匹配配送路线"
						style="width: 100%;" @visible-change="visibleChange($event)"
						@change="form.delivery_line_id = $event">
						<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="联系人" required>
					<el-input v-model="form.contacts" placeholder="请输入联系人"></el-input>
				</el-form-item>
				<el-form-item label="联系方式" required>
					<el-input v-model="form.phone" placeholder="请输入联系方式"></el-input>
				</el-form-item>
				<el-form-item label="省市区" required>
					<el-cascader ref="cascader" style="width:100%" v-model="addressVal" :props="{
                        value: 'code',
                        label: 'name'
                    }" :options="addressList" @change="handleAddressChange"></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" required>
					<el-input placeholder="输入详细地址快速定位位置" v-model="form.address" style="margin-bottom: 10px;">
						<el-button slot="append" icon="el-icon-search" @click.stop="searchMap">搜索</el-button>
					</el-input>
					<div class="map-content">
						<div id="pointsContainer" style="width:100%; height:100%"></div>
					</div>
				</el-form-item>
			</el-form>
			<div style="color: red;margin-left: 100px;margin-top: 20px;">
				<p>在地图上单击鼠标左键获取仓库的地理位置坐标</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				//省市区
				addressVal: [],
				addressName: [],
				addressList: [],
				// 新增、编辑弹窗
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				form: {
					id: '',
					name: '',
					contacts: '',
					phone: '',
					address: '',
					pro_code: '',
					city_code: '',
					area_code: '',
					delivery_line_id: 0,
					type: 2
				},
				routeList: [],
				//地图
				defaultLat: 39.984104,
				defaultLng: 116.307503,

				map: null,
				marker: null
			}
		},
		mounted() {
			this.getList()
			this.getAddressList()
			this.getRouteList()
		},
		methods: {
			getRouteList() {
				this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.routeList = data
						this.routeList.unshift({
							id: 0,
							title: "自动匹配"
						})
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 点击下拉框
			visibleChange(e) {
				if (e == true) {
					this.getRouteList()
				}
			},
			// 新增弹窗
			addDialog() {

				this.dialogVisible = true
				this.dialogType = 1
				this.form = {}
				this.form.type = 2
				this.addressVal = []
				this.$nextTick(() => {
					this.initMap(this.defaultLat, this.defaultLng)
				})
			},
			searchMap() {
				if (this.form.area_code == "") {
					this.$message.error("请选择省市区");
					return;
				}
				if (this.form.address == "") {
					this.$message.error("请输入要检索的位置");
					return;
				}
				this.$http.post(`erp/v1/common/search_map`, {
					map_address: this.addressName[0] + this.addressName[1] + this.addressName[2] + this.form
						.address
				}).then((res) => {
					const {
						code,
						msg,
						data
					} = res.data
					if (code == 200) {
						// 地图
						this.form.lat = data.lat
						this.form.lng = data.lng
						var center = new TMap.LatLng(data.lat, data.lng)
						this.map.setCenter(center)
						if (this.marker != null) {
							this.marker.setMap(null)
							this.marker = null
						}
						this.marker = new TMap.MultiMarker({
							map: this.map,
							geometries: [
								// 点标记数据数组
								{
									// 标记位置(纬度，经度，高度)
									position: center,
									id: 'marker',
								},
							],
						});
					} else {
						this.$message.error(msg);
					}
				});
			},
			getList() {
				this.$http.post(`erp/v1/warehouse/lst`, {
					page: this.page,
					pageNum: this.pageNum,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 省市区
			getAddressList() {
				this.$http.post(`erp/v1/common/all_district`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.addressList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 选择省市区
			handleAddressChange(e) {
				console.log(this.$refs["cascader"].getCheckedNodes()[0].pathLabels)
				this.form.pro_code = e[0]
				this.form.city_code = e[1]
				this.form.area_code = e[2]
				//省市区名称
				this.addressName[0] = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[0]
				this.addressName[1] = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[1]
				this.addressName[2] = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[2]
			},
			// 编辑弹窗
			openDialog(item) {


				this.form.id = item.id
				this.form.name = item.name
				this.form.contacts = item.contacts
				this.form.phone = item.phone
				this.form.address = item.address
				this.form.pro_code = item.province_code.pro_code
				this.form.city_code = item.city_code.city_code
				this.form.area_code = item.area_code.city_code
				this.form.lng = item.lng
				this.form.lat = item.lat
				this.form.type = item.type
				this.addressVal = [item.province_code.pro_code, item.city_code.city_code, item.area_code.city_code]

				this.$nextTick(() => {
					this.initMap(this.form.lat, this.form.lng)
				})
			},
			// 确定
			confirmFn() {
				if (!this.form.name) {
					this.$message.error('请输入仓库名称');
					return
				}
				if (!this.form.contacts) {
					this.$message.error('请输入联系人');
					return
				}
				if (!this.form.phone) {
					this.$message.error('请输入联系方式');
					return
				}
				if (!this.form.contacts) {
					this.$message.error('请输入联系人');
					return
				}
				if (!this.form.pro_code) {
					this.$message.error('请选择省市区');
					return
				}
				if (this.form.address.length <= 5) {
					this.$message.error('详细地址不能小于5个字');
					return
				}
				let url = this.dialogType == 1 ? '/erp/v1/warehouse/add' : '/erp/v1/warehouse/modify'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 删除
			removeFn(item) {
				this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`erp/v1/warehouse/delete`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			initMap(lat, lng) {
				if (this.map == null) {
					this.map = new TMap.Map(document.getElementById('pointsContainer'), {
						center: new TMap.LatLng(lat, lng),
						zoom: 12,
					});

				}
				if (this.marker != null) {
					this.marker.setMap(null)
					this.marker = null
				}
				this.marker = new TMap.MultiMarker({
					map: this.map,
					geometries: [
						// 点标记数据数组
						{
							// 标记位置(纬度，经度，高度)
							position: new TMap.LatLng(lat, lng),
							id: 'marker',
						},
					],
				});
				//监听点击事件添加marker
				this.map.on("click", (evt) => {
					if (this.marker != null) {
						this.marker.setMap(null)
						this.marker = null
					}
					this.form.lat = evt.latLng.lat
					this.form.lng = evt.latLng.lng
					this.marker = new TMap.MultiMarker({
						map: this.map,
						geometries: [
							// 点标记数据数组
							{
								// 标记位置(纬度，经度，高度)
								position: evt.latLng,
								id: 'marker',
							},
						],
					});
				});
			},
		},

	}
</script>

<style lang="scss" scoped></style>